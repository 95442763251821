
.form {
  padding: 5rem 0;

  &__text{
    margin: 0 0 3rem;
  }

  &__wrap{
    padding: $form-wrap-padding;
    max-width: $form-wrap;
    background-color: $form-wrap-back;
    border-radius: $form-wrap-radius;
    margin: 0 auto;
  }
 
  textarea,
  input {
    margin: $form-input-margin;
    padding: $form-input-padding;

    border-radius: $form-input-radius;
    color: $form-input-color;
    background-color: $form-input-back;

    &::placeholder {
      color: $form-input-color;
    }

    @include input-scale;
  }

  textarea {
    resize: none;
    height: 12rem;
  }

}