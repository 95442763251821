/* Base */
@import './base/reset';
@import './base/vars';
@import './base/mixins';
@import './base/containers';
@import './base/utils';

/* Blocks */
@import "blocks/_buttons.scss";
@import "blocks/_cookie.scss";
@import "blocks/_footer.scss";
@import "blocks/_header.scss";
@import "blocks/_mobile-nav.scss";

/* Index.html */
@import "index/_block2-section.scss";
@import "index/_block3-section.scss";
@import "index/_block4-section.scss";
@import "index/_block5-section.scss";
@import "index/_form-section.scss";
@import "index/_hero-section.scss";

/* Privacy.html */
@import "privacy/_hero-section.scss";

/* conditions.html */
@import "conditions/_hero-section.scss";
