//Media
$pc:        1200px;
$pc-sm:     1140px;
$lg-tab:    1024px;
$tab:       968px;
$tab-sm:    900px;
$sm-tab:    768px;
$lg-mob:    600px;
$mob:       480px;
$sm-mob:    375px;

//width-logo
$width-logo: 14rem;

//header
$background-humburger: #fff;
$background-header: rgba(25, 18, 26, 1);
$links-header-color: #fff;

//title-util
$title-util-size: 3.2rem;
$title-util-line: 4.2rem;
$title-util-transform: uppercase;
$title-margin: 0 0 3rem;
$title-util-size-lg: 4rem;
$title-util-line-lg: 4.2rem;

//cookie
$cookie-color: #fff;
$cookie-background: #000;
$cookie-border: #fff;
$cookie-box-shadow: #000;
$cookie-button-color: #fff;
$cookie-button-border: #fff;
$cookie-other-back: rgba(226, 167, 249, 1);
$cookie-other-color: rgba(25, 18, 26, 1);

//mixin
$hover-underline-color: #fff;

//footer
$footer-background: rgba(25, 18, 26, 1);
$footer-color: rgba(237, 237, 237, 1);
$footer-link-color: rgba(237, 237, 237, 1);

//body
$body-background: rgba(25, 18, 26, 1);
$body-color: rgba(237, 237, 237, 1);
$body-font-size: 1.4rem;
$body-line-height: 1.9rem;

//container
$container-width: 120rem;
$container-padding-lg: 0 8rem;
$container-padding-mb: 0 2rem;

//button-util
$button-back: rgba(226, 167, 249, 1);
$button-color: #000;
$button-padding: 2rem 0;
$button-radius: 1rem;
$button-text-trans: uppercase;

//form
$form-wrap: 50rem;
$form-wrap-padding: 0;
$form-wrap-back: inherit;
$form-wrap-radius: 0;
$form-input-padding: 2rem;
$form-input-radius: 2rem;
$form-input-color: #fff;
$form-input-back: rgba(255, 255, 255, 0.06);
$form-input-margin: 0 0 1rem;