.main{
  padding: 21rem 0 6rem;

  .flex{
    align-items: center;
  }
  .flex__wrap1{
    max-width: 55rem;
  }
  .flex__wrap2{
    max-width: 44rem;
  }

  &__text{
    margin: 0 0 2rem;
  }
}