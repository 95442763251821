.footer {
  padding: 5rem 0;
  color: $footer-color;
  background-color: $footer-background;

  a{
    text-decoration: none;
    font-size: 1.4rem;
    line-height: 1.6rem;
    color: $footer-link-color;
  }
  
  &__logo {
    max-width: $width-logo;
    @include scale;
  }
  
  &__bottom {
    font-size: 1.4rem;
    line-height: 2.1rem;
  }

  &__links_footer{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 2rem;
    margin: 0 0 5rem;
    &:last-child{
      margin: 0;
    }
  }
}
.links_footer {
  ul{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 2rem;
    li{
      a{
        @include hover-underline;
      }
    }
  }
}
