.cookie {
  position: fixed;
  width: 100%;
  bottom: 1.4rem;
  left: 1.5rem;
  z-index: 100;
  color: $cookie-color;

  &__wrap {
    width: 90%;
    max-width: 73.4rem;
    background: $cookie-background;
    border: 1px solid $cookie-border;
    box-shadow: 0px 0px 30px 0px $cookie-box-shadow;
    padding: 2rem;
    border-radius: 2.6rem;
  }

  &__text{
    margin: 0 0 2rem;
  }

  &__title {
    text-transform: uppercase;
    font-size: 2.4rem;
    line-height: 2.9rem;
    margin: 0 0 2rem;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    @include _1024{
      flex-direction: row;
    }
    a,
    button{
      color: $cookie-button-color;
      border: 1px solid $cookie-button-border;
      text-decoration: none;
      text-align: center;
      text-transform: uppercase;
      width: auto;
      border-radius: 5rem;
      padding: 2rem 6rem;
      font-size: 1.4rem;
      line-height: 1.7rem;
      @include scale;


      &.other_back{
        border: none;
        color: $cookie-other-color;
        background-color: $cookie-other-back;
      }
    }
  }
}