*,
*::before,
*::after {
  box-sizing: border-box;
}

ul,
ul[class],
ol[class] {
  padding: 0;
  margin: 0;
}


body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

html{
  font-size: 10px;
	scroll-behavior: smooth;
}

body {
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}


ul[class],
ol[class],
ul {
  list-style: none;
}


a:not([class]) {
  text-decoration-skip-ink: auto;
}

img,video{
  object-fit: cover;
}

svg,img,video {
  width: 100%;
  height: 100%;
  display: block;
}

iframe{
  width: 100%;
  height: 100%;
}


article > * + * {
  margin-top: 1em;
}

input,
button,
textarea,
select,
a {
  font: inherit;
  appearance: none;
  outline: none;
}


@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

.navbar-brand,
.nav-link,
.btn-close,
.btn{
	&:focus{
		box-shadow: none;
	}
}

.navbar-expand-md .navbar-nav .nav-link{
  padding: 0;
}

button,
a{
  width: 100%;
  display: block;
}
button{
  border: none;
  background-color: transparent;
}
span{
  display: block;
}
input,
textarea{
  background-color: transparent;
  border: none;
  width: 100%;
}

button{
  cursor: pointer;
}