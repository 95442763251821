.mobile-nav {
	position: fixed;
	top: 0;
	right: -100%;
	width: 100%;
	height: 100%;
  z-index: 99;
	background-color: rgba(17, 17, 17, 1);
	display: flex;
  flex-direction: column;
  align-items: center;
	justify-content: center;
	transition: all 0.2s ease-in;
	li{
		a {
			color: rgba(255, 255, 255, 1);
			font-size: 2rem;
			padding: .2rem 0;
			line-height: 1.4rem;
			text-decoration: none;
			@include hover-underline;
		}
	}
}

.mobile-nav--open {
	right: 0;
}

.mobile-nav__list {
	display: flex;
	flex-direction: column;
	align-items: center;
	row-gap: 3rem;

	& .active {
		opacity: 0.5;
	}
}

.mobile-nav-btn {
	--time: 0.1s;

	--width: 4rem;
	--height: 3rem;

	--line-height: .4rem;
	--spacing: .6rem;
	--radius: .4rem;

	height: 4.3rem;
	width: 4.3rem;
	display: flex !important;
	justify-content: center;
	align-items: center;
	background: transparent;
	border: none;
	padding: 0;

	@include _768{
		display: none !important;
	}

}

.nav-icon {
	position: relative;
	width: var(--width);
	height: var(--line-height);
	background-color: $background-humburger;
	border-radius: var(--radius);
}

.nav-icon::before,
.nav-icon::after {
	content: '';
	display: block;

	position: absolute;
	left: 0;

	width: var(--width);
	height: var(--line-height);

	border-radius: var(--radius);
	background-color: $background-humburger;
	transition: transform var(--time) ease-in,
		top var(--time) linear var(--time);
}

.nav-icon::before {
	top: calc(-1 * (var(--line-height) + var(--spacing)));
}

.nav-icon::after {
	top: calc(var(--line-height) + var(--spacing));
}

.nav-icon.nav-icon--active {
	background-color: transparent;
}

.nav-icon.nav-icon--active::before,
.nav-icon.nav-icon--active::after {
	top: 0;
	transition: top var(--time) linear,
	transform var(--time) ease-in var(--time);
}

.nav-icon.nav-icon--active::before {
	transform: rotate(45deg);
}

.nav-icon.nav-icon--active::after {
	transform: rotate(-45deg);
}

.mobile-nav-btn {
	z-index: 999;
}