.block5 {
  padding: 4rem 0;
  @include _1024{
    padding: 5rem 0;
  }

  &__wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    margin: 0 0 4rem;
  }

  &__block{
    width: 100%;
    background-color: rgba(255, 255, 255, 0.06);
    padding: 2rem;
    border-radius: 2rem;
    @include _768{
      width: 32%;
    }

    &_img{
      margin: 0 0 2rem;
    }

    &_text{
      span{
        font-size: 2rem;
        line-height: 2.4rem;
        margin: 0 0 2rem;
      }
    }
  }
}