.header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 100;
	background: $background-header;
	padding: 3rem 0;

	&__container{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__logo{
		max-width: $width-logo;
		margin: 0 2rem 0 0;
		a{
			@include scale;
		}
	}

}

.header__nav {
	position: relative;
	display: none;
	@include _768{
		display: flex;
		align-items: center;
	}

	ul{
		display: flex;
		justify-content: flex-end;
		align-items: center;
		flex-wrap: wrap;
		column-gap: 3rem;
		row-gap: 1rem;
		margin: 0 2rem 0 0;

		li{
			a{
				color: $links-header-color;
				font-size: 1.4rem;
				line-height: 1.7rem;
				text-decoration: none;
				@include hover-underline;
			}
		}
	}
}

.nav{
	&__cloud{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: inherit;
		z-index: -1;
	}
}