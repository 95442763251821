.btn_to_top {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  width: 5rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  line-height: 100%;
  background-color: rgba(11, 0, 26, 1);
  border-radius: 50%;
  border: 0.2rem solid rgb(72, 60, 8);
  color: rgba(255, 255, 255, 1);
  opacity: 0;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, opacity .5s, transform .3s;
  z-index: 2;
  body.pc &:focus,
  body.mobile &:active,
  body.pc &:hover {
    background-color: #fff;
    color: #000;
  }
  body.pc &:focus{
    transform: scale(1.05);
  }
  &.visible{
    opacity: 1;
  }
  @include _1024 {
    width: 6rem;
    height: 6rem;
    font-size: 2.5rem;
  }
}

.button_pink{
  text-transform: $button-text-trans;
  padding: $button-padding;
  border-radius: $button-radius;
  background-color: $button-back;
  color: $button-color;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  max-width: 26rem;

  @include scale;
}