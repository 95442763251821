.block3 {
  padding: 4rem 0;
  @include _1024{
    padding: 5rem 0;
  }

  &__wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    margin: 0 0 4rem;
  }
  &__block2,
  &__block{
    width: 100%;
    @include _768{
      width: 32%;
    }
    &_img2,
    &_img{
      margin: 0 0 2rem;
      max-width: 10rem;
    }
    &_img2{
      max-width: 100%;
    }

    &_text{
      span{
        font-size: 2rem;
        line-height: 2.4rem;
        margin: 0 0 2rem;
      }
    }
  }

  &__block{
    padding: 2rem;
    border-radius: 2rem;
    background: rgba(255, 255, 255, 0.06);
  }

  &__button{
    margin: 0 auto 8rem;
  }
}