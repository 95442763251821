body{
	overflow: visible;
	color: $body-color;
	background-color: $body-background;
	font-size: $body-font-size;
	line-height: $body-line-height;
	@include open-sans-reuglar;
	&.no-scroll{
		overflow: hidden;
	}
}

footer,
section,
main{
	position: relative;
}

img{
	border-radius: 4rem;
}

.center{
	text-align: center;
}

.wrapper{
  overflow: hidden;
}

.title_main{
	font-size: $title-util-size;
	line-height: $title-util-line;
	margin: $title-margin;
	@include open-sans-bold;
	@include _1024{
		font-size: 6rem;
		line-height: 6.6rem;
	}
}
.title_section{
	font-size: $title-util-size;
	line-height: $title-util-line;
	margin: $title-margin;
	@include open-sans-bold;
	@include _1024{
		font-size: $title-util-size-lg;
		line-height: $title-util-line-lg;
	}
}

// for blocks
.flex{
	display: flex;
	flex-direction: column;
	gap: 3rem;
	@include _768{
		justify-content: space-between;
		flex-direction: row;
	}
	&__wrap2,
	&__wrap1{
		@include _768{
			width: 50%;
		}
	}
	&__wrap1{
		@include _1024{
			width: 65%;
		}
	}
}
.background_img{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
}
.thanks{
	padding: 8rem 0;
	@include _1024{
		padding: 14rem 0 8.9rem;
	}

	&__title{
		text-transform: uppercase;
		font-size: 4.8rem;
		line-height: 7.2rem;
		margin: 0 0 5rem;
	}

	&__text{
		margin: 0 0 3rem;
	}

	&__button{
		max-width: 20rem;
	}
}