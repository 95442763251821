.block2 {
  padding: 4rem 0;
  @include _1024{
    padding: 5rem 0;
  }

  &__wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
    margin: 0 0 10rem;
  }

  &__block{
    width: 100%;
    @include _768{
      width: 23%;
    }

    &_img{
      width: 2rem;
      height: 3rem;
      img{
        object-fit: contain;
      }
    }

    &_text{
      span{
        font-size: 2rem;
        line-height: 2.4rem;
        margin: 0 0 1rem;
      }
    }
  }

  .flex{
    align-items: center;
  }
  .flex__wrap1{
    max-width: 55rem;
  }
  .flex__wrap2{
    max-width: 44rem;
  }
}